import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import SmoothImage from '@xFrame4/components/common/SmoothImage';
import { freshImageUrl } from '@xFrame4/common/Functions';
import { useAppStateSelector } from '@redux/hooks';
import { CategoryForTree } from '@business/menu/MenuTreeManager';

export interface MainCategoryOnIndexProps
{
    mainCategory: CategoryForTree;
}

const MainCategoryOnIndex: FunctionComponent<MainCategoryOnIndexProps> = (props) =>
{
    const navigate = useNavigate();
    const appLanguage = useAppStateSelector(state => state.layout.language);
    
    /** Render */
    return (
        <div 
            className="main-category-on-index d-flex flex-column align-items-center justify-content-center cursor-pointer"
            onClick={() => navigate(`/${appLanguage.code}/${props.mainCategory.id}`)}
        >
            <div className="main-category-on-index-image w-50">
                <SmoothImage 
                    src={freshImageUrl(props.mainCategory.logoImage?.url ?? '')}
                    alt={props.mainCategory.name}
                    width={props.mainCategory.logoImage?.width ?? 200}
                    height={props.mainCategory.logoImage?.height ?? 200}
                    className="img-fluid"
                />
            </div>

            <div className="main-category-on-index-name">
                {props.mainCategory.getTranslation(appLanguage.code).name}
            </div>
        </div>
    );
}

export default MainCategoryOnIndex;
import { capitalizeFirstLetter } from '@xFrame4/common/Functions';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import CategoryGenerated, { CategoryGeneratedEntityManager } from './generated/Category.generated';
import { imageInfoGraphQlDetails } from '@xFrame4/business/Constants';
import { ImageInfo } from '@xFrame4/business/Types';

export class CategoryEntityManager extends CategoryGeneratedEntityManager<Category>
{
    constructor(parameters?: EntityManagerParameters<Category>)
    {
        super({
            createEntity: () => parameters?.createEntity ? parameters.createEntity() : new Category(),
            fields: [
                { name: 'hasLogo', type: BusinessEntityFieldType.Boolean, isInput: false },
                { name: 'logoImage', type: BusinessEntityFieldType.Custom, inputProperty: 'logoImageFile', customGraphQl: imageInfoGraphQlDetails },
                ...parameters?.fields ?? [],
            ]
        });

        // set the recursive fragment depth for the parent field (there can be more levels of categories)
        this.fields.find(f => f.name === 'parent')!.recursiveFragmentDepth = 5; // default is 1
    }
}

export class Category extends CategoryGenerated
{
    static _manager: CategoryEntityManager;
    static get manager(): CategoryEntityManager
    {
        if (!this._manager) this._manager = new CategoryEntityManager();
        return this._manager;
    }

    hasLogo: boolean = false;
    logoImage: ImageInfo | null = null;

    /**
     * Check if the category is a main category
     */
    get isMainCategory(): boolean
    {
        return this.parent === null;
    }

    /**
     * Show the first level categories in the sub menu.
     */
    get isFirstLevelCategory(): boolean
    {
        return this.parent !== null && this.parent.parent === null;
    }

    /**
     * The parent tree of the category as a string
     */
    get parentTreeAsString(): string
    {
        if (this.parent == null) return '';

        // @ts-ignore
        if (this.parent.parentTreeAsString === '') 
        {
            return this.parent.nameHu;
        }
        else
        {
            // @ts-ignore
            return this.parent.parentTreeAsString + ' > ' + this.parent.nameHu;
        }
    }

    /**
     * Get the name of the category as a tree. Eg: "Main Category > Sub Category > Sub Sub Category"
     */
    get nameAsTree(): string
    {
        if (this.parent == null) return this.nameHu;

        // @ts-ignore
        return this.parent.nameAsTree + ' > ' + this.nameHu;
    }
    
    /**
     * Get translation for the given language
     * 
     * @param language Language code
     */
    getTranslation(language: string): CategoryTranslation
    {
        let translation: CategoryTranslation = {
            // @ts-ignore
            name: this['name' + capitalizeFirstLetter(language)] ?? ''
        };

        return translation;
    }
}

export type CategoryTranslation = {
    name: string;
};

export default Category;
import { FunctionComponent, useEffect, useRef } from 'react';
import SmoothImage from '@xFrame4/components/common/SmoothImage';
import { freshImageUrl } from '@xFrame4/common/Functions';
import { useAppActionDispatch, useAppStateSelector } from '@redux/hooks';
import { CategoryForTree } from '@business/menu/MenuTreeManager';
import { setFirstLevelCategoryId } from '@redux/menuTreeSlice';

export interface FirstLevelCategoryNavItemProps
{
    firstLevelCategory: CategoryForTree;
}

const FirstLevelCategoryNavItem: FunctionComponent<FirstLevelCategoryNavItemProps> = (props) =>
{
    const appLanguage = useAppStateSelector(state => state.layout.language);
    const dispatch = useAppActionDispatch();
    const selectedFirstLevelCategoryId = useAppStateSelector(state => state.menuTree.selectedFirstLevelCategoryId);
    const ref = useRef<HTMLDivElement>(null);

    // Scroll into view if this category is selected
    useEffect(() =>
    {
        if (ref.current && props.firstLevelCategory.id == selectedFirstLevelCategoryId)
        {
            console.log('scrolling nav item into view');
            ref.current.scrollIntoView({
                behavior: 'smooth',
                inline: 'center' // center the element horizontally
            });
        }
    }, [selectedFirstLevelCategoryId]);

    /** Render */
    return (
        <div 
            className={'first-level-category-nav-item d-flex flex-column align-items-center me-3 cursor-pointer ' + (props.firstLevelCategory.id == selectedFirstLevelCategoryId ? 'active' : '')}
            ref={ref}
            onClick={() => dispatch(setFirstLevelCategoryId(props.firstLevelCategory.id!))}
        >
            <div className="first-level-category-nav-item-image mb-1">
                <SmoothImage 
                    src={freshImageUrl(props.firstLevelCategory.logoImage?.url ?? '')}
                    width={props.firstLevelCategory.logoImage?.width ?? 50}
                    height={props.firstLevelCategory.logoImage?.height ?? 50}
                />
            </div>

            <div className="first-level-category-nav-item-name small">
                {props.firstLevelCategory.getTranslation(appLanguage.code).name}
            </div>
        </div>
    );
}

export default FirstLevelCategoryNavItem;
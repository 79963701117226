import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';

import Category from '@business/menu/Category';

export enum MenuItemType
{
    FOOD = 'FOOD',
    DRINK = 'DRINK',

}
export enum MenuItemStatus
{
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',

}

export class MenuItemGeneratedEntityManager<B extends MenuItemGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'MenuItem',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'orderNo', type: BusinessEntityFieldType.Integer, isRequiredInput: true },
				{ name: 'nameRo', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'nameHu', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'nameEn', type: BusinessEntityFieldType.VarChar },
				{ name: 'detailsRo', type: BusinessEntityFieldType.Text },
				{ name: 'detailsHu', type: BusinessEntityFieldType.Text },
				{ name: 'detailsEn', type: BusinessEntityFieldType.Text },
				{ name: 'allergensRo', type: BusinessEntityFieldType.Text },
				{ name: 'allergensHu', type: BusinessEntityFieldType.Text },
				{ name: 'allergensEn', type: BusinessEntityFieldType.Text },
				{ name: 'ingredientsRo', type: BusinessEntityFieldType.Text },
				{ name: 'ingredientsHu', type: BusinessEntityFieldType.Text },
				{ name: 'ingredientsEn', type: BusinessEntityFieldType.Text },
				{ name: 'weightsVolumes', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'price', type: BusinessEntityFieldType.Decimal, isRequiredInput: true },
				{ name: 'category', type: BusinessEntityFieldType.ForeignKey, isRequiredInput: true, inputName: 'categoryId', inputProperty: 'category.id', relatedManager: Category.manager },
				{ name: 'type', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'status', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'energyKcal', type: BusinessEntityFieldType.Decimal },
				{ name: 'energyKj', type: BusinessEntityFieldType.Decimal },
				{ name: 'fatsG', type: BusinessEntityFieldType.Decimal },
				{ name: 'saturatedFatsG', type: BusinessEntityFieldType.Decimal },
				{ name: 'carbohydratesG', type: BusinessEntityFieldType.Decimal },
				{ name: 'sugarsG', type: BusinessEntityFieldType.Decimal },
				{ name: 'proteinsG', type: BusinessEntityFieldType.Decimal },
				{ name: 'saltG', type: BusinessEntityFieldType.Decimal },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'menuItems',
        });
    }

}
export class MenuItemGenerated extends BusinessEntity
{
	/** Order number of the item within the category. */
	orderNo: number = 0;
	/** Name of the item in Romanian. */
	nameRo!: string;
	/** Name of the item in Hungarian. */
	nameHu!: string;
	/** Name of the item in English. */
	nameEn: string = '';
	/** Details of the item in Romanian. */
	detailsRo: string = '';
	/** Details of the item in Hungarian. */
	detailsHu: string = '';
	/** Details of the item in English. */
	detailsEn: string = '';
	/** Allergens of the item in Romanian. */
	allergensRo: string = '';
	/** Allergens of the item in Hungarian. */
	allergensHu: string = '';
	/** Allergens of the item in English. */
	allergensEn: string = '';
	/** Ingredients of the item in Romanian. */
	ingredientsRo: string = '';
	/** Ingredients of the item in Hungarian. */
	ingredientsHu: string = '';
	/** Ingredients of the item in English. */
	ingredientsEn: string = '';
	/** Weights or volumes of the components, separated by slashes. */
	weightsVolumes!: string;
	/** Price of the item. */
	price!: number;
	/** Category of the item, linking to the Category table. */
	category!: Category;
	/** Type of the item: FOOD or DRINK. */
	type!: MenuItemType;
	/** Status of the item: ACTIVE or INACTIVE. */
	status: MenuItemStatus = MenuItemStatus.ACTIVE;
	/** Energy content in kilocalories. */
	energyKcal: number | null = null;
	/** Energy content in kilojoules. */
	energyKj: number | null = null;
	/** Total fat content in grams. */
	fatsG: number | null = null;
	/** Saturated fat content in grams. */
	saturatedFatsG: number | null = null;
	/** Carbohydrates content in grams. */
	carbohydratesG: number | null = null;
	/** Sugar content in grams. */
	sugarsG: number | null = null;
	/** Protein content in grams. */
	proteinsG: number | null = null;
	/** Salt content in grams. */
	saltG: number | null = null;

}

export default MenuItemGenerated;